import {
  Button,
  DatePicker,
  LoadingButton,
  SwitchButton,
} from "components/form";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { IoCloseSharp, IoTrashOutline } from "react-icons/io5";
import { PiCopySimpleLight } from "react-icons/pi";
import "./ScheduleSetting.scss";
import CustomModal from "components/modals/CustomModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toastMessage } from "utils/toastMessage";
import { addScheduledApi, getScheduledApi } from "services/scheduled";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import CommonPagination from "common/CommonPagination";
import ReactDatePicker from "react-datepicker";
import moment from "moment-timezone";
import {
  convert12To24Hour,
  dateFormat,
  dateFormatMDYW,
  dateFormatUtc,
  setDynamicTime12Hour,
  timeFormat,
  timeFormat_24,
} from "utils/dateFormat";
import Placeholder from "components/placeholder/Placeholder";
import { generateTimeIntervals } from "utils/formValidator";

const ScheduleSetting = () => {
  const [serviceModal, setServiceModal] = useState(false);
  const [servicesList, setServicesList] = useState([]);
  const [searchParam, setSearchParam] = useSearchParams();
  const [loader, onShow, onHide] = FullPageLoader();
  let activePage = +searchParam.get("page") || 1;
  const [unavailableModal, setUnavailableModal] = useState(false);
  const [total, setTotal] = useState(null);
  const [partialSelectedService, setPartialSelectedService] = useState({});
  const [selectedService, setSelectedService] = useState({});
  const [unAvailableDate, setUnavailableDate] = useState("");
  const [unAvailableDateList, setUnavailableDateList] = useState([]);
  const [addIntervalData, setAddIntervalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scheduleError, setScheduleError] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchingServicesList();
  }, []);

  useEffect(() => {
    if (selectedService?.duration) {
      const weekDays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const newIntervalData = weekDays.map((day, index) => ({
        day: index,
        name: day,
        status: true,
        range: [],
        tempRange: { startTime: null, endTime: null },
      }));
      setAddIntervalData(newIntervalData);
    }
  }, [selectedService]);

  // Fetching Services List
  const fetchingServicesList = async () => {
    try {
      onShow();
      const sendData = { search: "", page: activePage - 1, pageSize: 10 };
      const { data } = await getScheduledApi(sendData);
      if (data?.code === 200) {
        setServicesList(data?.data);
        setTotal(data?.totalItems || null);
      } else {
        toastMessage(data?.message, "error", "priorityList");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  // Pagination Handler
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  const updateTempRange = (day, field, value) => {

    if(field==="startTime"){
      setAddIntervalData((prevData) =>
      prevData.map((item) =>
        item?.day === day
          ? { ...item, tempRange: { ...item?.tempRange, [field]: value,endTime:null } }
          : item
      )
    );
    }

    if(field==="endTime"){

      setAddIntervalData((prevData) =>
        prevData.map((item) =>
          item?.day === day
            ? { ...item, tempRange: { ...item?.tempRange, [field]: value } }
            : item
        )
      );
    }
  };



  // Validation
  const validation = () => {
    let error = {};
    let status = true;

    addIntervalData.forEach((data, index) => {
      if (data?.status && !data?.range.length) {
        error[`range${index}`] = "Please select time for this day";
        status = false;
      }
      setScheduleError(error);
    });
    return status;
  };

  // Add Interval Handler
  const addInterval = (day, index) => {
    const tempData = [...addIntervalData];

    if (
      tempData[index]?.tempRange?.startTime &&
      tempData[index]?.tempRange?.endTime
    ) {
      setAddIntervalData((prevData) =>
        prevData.map((item) => {
          return item?.day === day
            ? {
                ...item,
                range: [
                  ...item?.range,
                  {
                    startTime: item?.tempRange?.startTime,
                    endTime: item?.tempRange?.endTime,
                  },
                ],
                tempRange: { startTime: null, endTime: null },
              }
            : item;
        })
      );
    } else {
      toastMessage(
        `Please select ${
          !tempData[index]?.tempRange?.startTime ? "start" : "end"
        } time before adding interval`,
        "error",
        "addInterval"
      );
    }
  };

  // For changing day status
  const handleSlotDataChange = (day, field, value) => {
    setAddIntervalData((prevData) =>
      prevData.map((item) =>
        item.day === day ? { ...item, [field]: value } : item
      )
    );
  };

  // Handle Date Change
  const handleDateChange = (date) => {
    setUnavailableDate(dateFormat(date));
    const tempData = [...unAvailableDateList];
    tempData.push(dateFormatUtc(date));
    setUnavailableDateList(tempData);
  };

  // Add Schedule Handler
  const addScheduleHandler = async () => {
    try {
      if (addIntervalData.length && validation()) {
        setLoading(true);
        const sendData = {
          _id: selectedService?._id,
          consultationAvailability: addIntervalData.map((data) => {
            if (data?.range && data.range.length > 0) {
              return {
                status: data?.status,
                day: data?.day,
                range: data.range.map((time) => ({
                  startTime: convert12To24Hour(time?.startTime),
                  endTime: convert12To24Hour(time?.endTime),
                })),
              };
            } else {
              return {
                status: data.status,
                day: data.day,
              };
            }
          }),
          consultationUnavailability: unAvailableDateList,
        };


        const res = await addScheduledApi(sendData);
        if (res?.data?.code === 200) {
          toastMessage(res?.data?.message, "success", "addScheduled");
          navigate("/dashboard/manage-services?serviceType=oneToOne");
        } else {
          toastMessage(res?.data?.message, "error", "addScheduled");
        }
      } else {
        toastMessage(
          !validation()
            ? "Either change status or add time interval"
            : "Please select service to be scheduled",
          "error",
          "addScheduled"
        );
      }
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const getValidTimes = (minTime, duration) => {
    const validTimes = [];
    // console.log(minTime, "MinTime", duration, "Duration");
    return false;
    let currentTime = moment(minTime);
    // console.log(currentTime.toDate(), "CurrentTimes outside loop");

    while (currentTime.isBefore(moment("23:59", "HH:mm"))) {
      if (currentTime.minutes() % duration === 0) {
        validTimes.push(currentTime.toDate());
        console.log(currentTime.toDate(), "CurrentTimes");
      }
      currentTime.add(duration, "minutes");
    }

    return validTimes ? validTimes : [];
  };

  return (
    <>
      <div className="schedule_settings">
        {loader}
        <div className="dashboard_title">
          <h3>Schedule 1:1 Consultations</h3>
        </div>
        <div className="dashboard_content_inner">
          <div className="schedule_header">
            {selectedService?._id && (
              <h5>
                {selectedService?.title}
                <span>
                  Duration : {selectedService?.duration || "---"} mins
                </span>
              </h5>
            )}
            <div className="price_section">
              {selectedService?._id && (
                <p>
                  <h6>Pricing</h6>
                  {selectedService?.pricing?.showSlashedAmount ? (
                    <>
                      <del>₹{Number(selectedService?.pricing?.amount)} </del>
                      <b>
                        ₹
                        {Number(selectedService?.pricing?.amount) -
                          Number(selectedService?.pricing?.slashAmount)}
                      </b>
                    </>
                  ) : (
                    `₹${Number(selectedService?.pricing?.amount)}`
                  )}
                </p>
              )}
              <Button
                className="dashboard_button"
                onClick={() => setServiceModal(true)}
              >
                {/* Change Service */}
                Select Service
              </Button>
            </div>
          </div>

          <div className="schedule_content_wrap">
            <div className="weekly_hours">
              <h4>Weekly Hours</h4>

              {addIntervalData && addIntervalData.length ? (
                addIntervalData.map((data, index,intervalArr) => (
                  <div className="schedule_card" key={index}>
                    <p className="button">
                      <SwitchButton
                        checked={data?.status}
                        onChange={(e) =>
                          handleSlotDataChange(
                            data.day,
                            "status",
                            e.target.checked
                          )
                        }
                      />
                      <span>{data?.name}</span>
                    </p>

                    <ul>
                      {data.range &&
                        data?.range?.length > 0 &&
                        data.range.map((rangeItem, rangeIndex) => {
                          return (
                            <li key={rangeIndex}>
                              <span className="form-control input">
                                {rangeItem.startTime}
                              </span>{" "}
                              -
                              <span className="form-control input">
                                {/* {timeFormat(rangeItem.endTime)} */}
                                {rangeItem?.endTime}
                              </span>
                              <button
                                type="button"
                                className="delete_button"
                                onClick={() =>
                                  handleSlotDataChange(
                                    data?.day,
                                    "range",
                                    data?.range.filter(
                                      (data, i) => i !== rangeIndex
                                    )
                                  )
                                }
                              >
                                <IoTrashOutline />
                              </button>
                            </li>
                          );
                        })}
                      <li className="has_time_picker">
                      <select
                          name="startTime"
                          className="form-control input cursor-pointer"
                          value={data?.tempRange?.startTime || ""}
                          onChange={(e) => {
                            const { value } = e.target;
                           

                            updateTempRange(
                              data.day,
                              "startTime",
                              value
                            );
                          }}
                        >
                          <option value="">Select</option>
                          {generateTimeIntervals(
                            {startTime:data?.tempRange?.startTime,
                            endTime:data?.range.at(-1),
                            duration:selectedService?.duration,type:"startTime"}
                          ).map((ele) => (
                            <>
                              <option value={ele} key={ele}>
                                {ele}
                              </option>
                            </>
                          ))}
                        </select>
                        -
                       
                        <select
                          name="endTime"
                          className="form-control input cursor-pointer"
                          value={data?.tempRange?.endTime || ""}
                          onChange={(e) => {
                            const { value } = e.target;
                            
                            updateTempRange(
                              data.day,
                              "endTime",
                              // dateTimeWithDynamicTime
                              value
                            );
                          }}
                        >
                          <option value="">Select</option>
                          {generateTimeIntervals(
                           {startTime: data?.tempRange?.startTime,
                            duration:selectedService?.duration,type:"endTime"}
                          ).map((ele) => (
                            <>
                              <option value={ele} key={ele}>
                                {ele}
                              </option>
                            </>
                          ))}
                        </select>
                      </li>
                    </ul>
                    <div className="button_section">
                      <Button
                        className="dashboard_button light"
                        onClick={() => addInterval(data?.day, index)}
                      >
                        Add Slot
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                <Placeholder text={"Select Service To Be Schedule"} />
              )}
            </div>
            <div className="Unavailability_wrap">
              <h4>Weekly Hours</h4>

              <div className="unavailability_card">
                <h5>Date-specific Unavailability</h5>
                <p>Add dates when you will be unavailable to take calls</p>
                <Button
                  className={"dashboard_button"}
                  onClick={() => {
                    selectedService?._id
                      ? setUnavailableModal(true)
                      : toastMessage(
                          "Please select service",
                          "error",
                          "serviceError"
                        );
                  }}
                >
                  + Add date
                </Button>
              </div>
              <ul>
                {unAvailableDateList &&
                  unAvailableDateList.length > 0 &&
                  unAvailableDateList.map((data, index) => (
                    <li key={index}>
                      <b>{dateFormatMDYW(data)}</b>
                      <span className="tag">Unavailable</span>{" "}
                      <button
                        onClick={() =>
                          setUnavailableDateList((prev) =>
                            prev.filter((date) => date !== data)
                          )
                        }
                        type="button"
                        className="cancel_button"
                      >
                        <IoCloseSharp />
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <div className="button_wrap button_center">
            <LoadingButton
              loading={loading}
              type="button"
              onClick={addScheduleHandler}
            >
              Save
            </LoadingButton>
          </div>
        </div>
      </div>

      {/* Non Scheduled Services */}
      <CustomModal
        show={serviceModal}
        handleClose={() => {
          if (
            partialSelectedService &&
            selectedService &&
            partialSelectedService._id !== selectedService
          ) {
            setPartialSelectedService(selectedService);
          } else if (!selectedService) {
            setPartialSelectedService(null);
          }
          setServiceModal(false);
        }}
        className={"serviceModal"}
      >
        <h3>Please select a service.</h3>
        <ul>
          {servicesList && servicesList.length ? (
            servicesList.map((data) => {
              return (
                <li
                  key={data._id}
                  className={
                    partialSelectedService?._id === data._id && "active"
                  }
                  onClick={() => {
                    setPartialSelectedService(data);
                  }}
                >
                  <h5>{data?.title || "---"}</h5>
                  <p>
                    <span>Duration : {data?.duration || "---"} mins</span>
                    <span>
                      Pricing :{" "}
                      {data?.pricing?.showSlashedAmount ? (
                        <>
                          ₹<del>{Number(data?.pricing?.slashAmount)}</del>
                          <b>{`₹${Number(data?.pricing?.amount)}`}</b>
                        </>
                      ) : (
                        `₹${Number(data?.pricing?.amount)}`
                      )}
                    </span>
                  </p>
                </li>
              );
            })
          ) : (
            <Placeholder text={"No services left to be scheduled"} />
          )}
        </ul>
        <CommonPagination
          activePage={activePage}
          total={total}
          handlePageChange={handlePageChange}
        />

        {!servicesList || !servicesList?.length ? null : (
          <div className="button_wrap button_center">
            <Button
              onClick={() => {
                setServiceModal(false);
                if (
                  partialSelectedService &&
                  selectedService &&
                  partialSelectedService._id !== selectedService
                ) {
                  setPartialSelectedService(selectedService);
                } else if (!selectedService) {
                  setPartialSelectedService(null);
                }
              }}
              className={"light"}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setSelectedService(partialSelectedService);
                setServiceModal(false);
              }}
            >
              Select
            </Button>
          </div>
        )}
      </CustomModal>

      {/* Unavailable Modal */}
      <CustomModal
        show={unavailableModal}
        handleClose={() => setUnavailableModal(false)}
        className={"Unavailability"}
      >
        <h3>Please select the date(s) when you will not be available.</h3>

        <DatePicker
          minDate={Date.now()}
          selected={unAvailableDate}
          onChange={(date) => handleDateChange(date)}
          inline
        />

        <div className="button_wrap button_center">
          <Button
            className={"light"}
            onClick={() => {
              setUnavailableModal(false);
              setUnavailableDateList([]);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setUnavailableModal(false);
            }}
          >
            Select
          </Button>
        </div>
      </CustomModal>
    </>
  );
};

export default ScheduleSetting;
