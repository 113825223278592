import React, { useEffect, useState } from "react";
import "./DashboardHome.scss";
import EarningPolarChart from "./chart/EarningPolarChart";
import EarningLineChart from "./chart/EarningLineChart";
import { getExpertDetailBySlug } from "services/master";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { MdOutlineContentCopy } from "react-icons/md";
import { toastMessage } from "utils/toastMessage";
import {
  addPixelApi,
  dashboardBookingApi,
  dashboardYearlyGraphApi,
  fetchingPixelApi,
  pieChartDataApi,
  todayBookingApi,
} from "services/dashboard";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import Placeholder from "components/placeholder/Placeholder";
import {
  dashboardDateFilter,
  dashboardFilter,
  dateFormat,
  dateFormatYear,
  timeFormat,
} from "utils/dateFormat";
import { Button, Input, LoadingButton, Select } from "components/form";
import DateFilter from "./priorityDMS/DateFilter";
import { GoArrowUpRight } from "react-icons/go";
import { Dropdown, DropdownButton } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import TodayBooking from "./chart/TodayBooking";
import CustomModal from "components/modals/CustomModal";

const DashboardHome = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  const [pixelModal, setPixelModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const year = +searchParam.get("year") || 2024;
  const serviceType = searchParam.get("serviceType") || "oneToOne";
  let fromDate = searchParam.get("fromDate") || "";
  let toDate = searchParam.get("toDate") || "";
  const subscriptionType = searchParam.get("subscriptionType") || "oneTime";
  const [pixelData, setPixelData] = useState({
    facebookPixel: "",
    googleTagManager: "",
    googlePixel: "",
    googleLabel: "",
  });
  const [loader, onShow, onHide] = FullPageLoader();
  const [linearData, setLinearData] = useState({});
  // const fitzureInfo = JSON.parse(localStorage.getItem("fitzure_info"));
  const { personalInfo } = useSelector((state) => state.config);

  const copyTextHandler = () => {
    const baseUrl = window.location.origin;
    navigator.clipboard.writeText(
      // `${baseUrl}/fitzure_web_build/#/expert-details/${personalInfo?.slug}`
      `${baseUrl}/${personalInfo?.slug}`
    );
    toastMessage("Text Copied Successfully", "success", "PublishUrl");
  };

  const fetchingBookingsData = async () => {
    try {
      onShow();
      const { data } = await dashboardBookingApi();
      if (data?.code === 200) {
        setLinearData(data?.data);
      } else {
        toastMessage(data?.message, "error", "fetchingBookingsData");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      onHide();
    }
  };

  // get Pixel Data
  const fetchingPixelData = async () => {
    if (personalInfo?.slug) {
      try {
        onShow();
        const { data } = await fetchingPixelApi(personalInfo?.slug);
        if (data?.code === 200) {
          setPixelData({
            facebookPixel: data?.data?.facebookPixel,
            googleLabel: data?.data?.googleLabel,
            googlePixel: data?.data?.googlePixel,
            googleTagManager: data?.data?.googleTagManager,
          });
        } else {
          toastMessage(data?.message, "error", "setPixelData");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        onHide();
      }
    }
  };

  const addPixelData = async () => {
    if (
      (pixelData?.googleLabel && !pixelData?.googlePixel) ||
      (!pixelData?.googleLabel && pixelData?.googlePixel)
    ) {
      toastMessage(
        "Please provide both google pixel id and google label",
        "error",
        "KeyMissing"
      );
      return false;
    }
    try {
      setLoading(true);
      const { data } = await addPixelApi(pixelData);
      if (data?.code === 200) {
        setPixelModal(false);
        toastMessage(data?.message, "success", "addPixel");
      } else {
        toastMessage(data?.message, "success", "addPixel");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchingBookingsData();
  }, []);

  useEffect(() => {
    if (pixelModal) {
      fetchingPixelData();
    }
  }, [pixelModal]);

  return (
    <>
      <div className="dashboard_home">
        <div className="dashboard_title">
          <h3>Dashboard</h3>
          <div className="has_child">
         
            <p className="copyUrl">
              <Link
                target="_blank"
                // to={`/expert-details/${personalInfo?.slug}`}
                to={`/${personalInfo?.slug}`}
              >
                <img src={personalInfo?.image} />{" "}
                <strong>{`${window.location.origin}/${personalInfo?.slug}`}</strong>
                <GoArrowUpRight />
              </Link>

              <button type="button">
                <MdOutlineContentCopy onClick={copyTextHandler} />
              </button>
            </p>
            <Button className="button mx-4" onClick={() => setPixelModal(true)}>
              Add Pixel
            </Button>
          </div>
        </div>
        
        <div className="dashboard_content_inner spacing">
          <div className="card_section_wrap spacing">
            <div className="user_info">
              <h4>
                {`Welcome ${personalInfo?.firstName} ${personalInfo?.lastName} `}
                !
              </h4>
              <span>Lets’s make great things happen today.</span>
            </div>
            <div className="card_wrap">
              <div className="_card">
                <p>Overall Bookings</p>
                <h4>{(linearData && linearData?.totalBookings) || 0}</h4>
              </div>
              <div className="_card">
                <p>Completed Bookings</p>
                <h4>{linearData?.completedBookings || 0}</h4>
              </div>
              <div className="_card">
                <p>Total Revenue (₹)</p>
                <h4>
                  {(linearData &&
                    Number(linearData?.totalRevenue).toFixed(2)) ||
                    0} 
                </h4>
              </div>
              <div className="_card">
                <p>Total Earning (₹)</p>
                <h4>
                  {(linearData &&
                    Number(linearData?.totalEarning).toFixed(2)) ||
                    0}
                </h4>
              </div>
            </div>
          </div>

          <div className="booking_summery spacing">
            <div className="booking_chart _card">{<EarningPolarChart />}</div>
            <TodayBooking />
          </div>

          <div className="earning _card">
            <EarningLineChart />
          </div>

          {/* Add Pixel Modal */}

          <CustomModal
            show={pixelModal}
            handleClose={() => setPixelModal(false)}
            className={"sm"}
          >
            <div className="form_field">
              <Input
                type="text"
                name="fbPixel"
                value={pixelData?.facebookPixel || ""}
                placeholder="Facebook Pixel Id"
                label="Facebook Pixel Id"
                maxlength="100"
                onChange={(e) =>
                  setPixelData({ ...pixelData, facebookPixel: e.target.value })
                }
              />
            </div>
            <div className="form_field">
              <Input
                type="text"
                name="googleTagManager"
                value={pixelData?.googleTagManager || ""}
                placeholder="Google Analytics Id"
                label="Google Analytics Id"
                maxlength="100"
                onChange={(e) =>
                  setPixelData({
                    ...pixelData,
                    googleTagManager: e.target.value,
                  })
                }
              />
            </div>
            <div className="form_field">
              <Input
                type="text"
                name="googlePixel"
                value={pixelData?.googlePixel || ""}
                placeholder="Google Ads Id"
                label="Google Ads Id"
                maxlength="100"
                onChange={(e) =>
                  setPixelData({
                    ...pixelData,
                    googlePixel: e.target.value,
                  })
                }
              />
            </div>
            <div className="form_field">
              <Input
                type="text"
                name="googleLabel"
                value={pixelData?.googleLabel || ""}
                placeholder="Enter Google Label"
                label="Google Label"
                maxlength="100"
                onChange={(e) =>
                  setPixelData({
                    ...pixelData,
                    googleLabel: e.target.value,
                  })
                }
              />
            </div>
            <div className="button_wrap button_right mt-3">
              <LoadingButton loading={loading} onClick={addPixelData}>
                Add Pixel
              </LoadingButton>
            </div>
          </CustomModal>
        </div>
      </div>
    </>
  );
};

export default DashboardHome;
